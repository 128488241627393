<template>
  <div>
    <v-container class="page-title page-title--advanced">
      <v-row>
        <v-col class="page-title__title-box">
          <h4 class="page-title__title">{{ $t('heading.instance.advanced.autoUpdates.title') }}</h4>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col cols="12" class="pb-2">
          <auto-updates-card-skeleton v-if="fetchingAutoUpdates" />
          <v-card elevation="4" v-else>
            <v-card-text
              class="d-flex justify-space-between pb-6 text--gray-darken2"
              :class="autoUpdatesEnabled ? '' : ''"
            >
              <div>
                <h6>{{ $t('heading.instance.advanced.autoUpdates.upgrade.title') }}</h6>
                <p class="mb-0  mt-1">
                  {{ $t('heading.instance.advanced.autoUpdates.upgrade.subtitle') }}
                </p>
              </div>
              <div class="d-flex align-center">
                <ButtonSwitch
                  :loading="submitAutoUpgrades"
                  :value="autoUpdates.auto_update ? true : false"
                  @click.native.stop.prevent="toggleAutoUpgrades"
                />
              </div>
            </v-card-text>
            <v-expand-transition>
              <div v-if="autoUpdates.auto_update">
                <v-divider />
                <v-card-text>
                  <v-select
                    outlined
                    hide-details=""
                    xlarge
                    :value="autoUpdates.auto_update"
                    @change="changeAutoUpdate"
                    :items="options"
                    item-text="label"
                    item-value="value"
                    :menu-props="{
                      offsetY: true,
                    }"
                  >
                    <template v-slot:no-data>
                      <loader />
                    </template>
                  </v-select>
                </v-card-text>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>

        <v-col cols="12" class="pb-2">
          <auto-updates-card-skeleton v-if="fetchingAutoUpdates" />

          <v-card elevation="4" v-else>
            <v-card-text class="d-flex justify-space-between text--gray-darken2">
              <div>
                <h6>{{ $t('heading.instance.advanced.autoUpdates.upgrade.plugin.title') }}</h6>
                <p class="mb-0  mt-1">
                  {{ $t('heading.instance.advanced.autoUpdates.upgrade.plugin.subtitle') }}
                </p>
              </div>
              <div class="d-flex align-center">
                <ButtonSwitch
                  :loading="submitAutoUpgradePlugins"
                  :value="autoUpdates.auto_update_plugins ? true : false"
                  @click.native.stop.prevent="toggleAutoUpgradePlugins"
                />
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" class="pb-2">
          <auto-updates-card-skeleton v-if="fetchingAutoUpdates" />

          <v-card elevation="4" v-else>
            <v-card-text class="d-flex justify-space-between text--gray-darken2">
              <div>
                <h6>{{ $t('heading.instance.advanced.autoUpdates.upgrade.theme.title') }}</h6>
                <p class="mb-0 mt-1">
                  {{ $t('heading.instance.advanced.autoUpdates.upgrade.theme.subtitle') }}
                </p>
              </div>
              <div class="d-flex align-center">
                <ButtonSwitch
                  :loading="submitAutoUpgradeThemes"
                  :value="autoUpdates.auto_update_themes ? true : false"
                  @click.native.stop.prevent="toggleAutoUpgradeThemes"
                />
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Api from "@/apis/Api";

import ButtonSwitch from "../../../components/buttons/ButtonSwitch.vue";
import Loader from "../../../components/Loader.vue";
import AutoUpdatesCardSkeleton from "../../../components/cards/AutoUpdatesCardSkeleton.vue";

export default {
  mounted() {
    this.fetching = false;
    this.fetchAutoUpdates();
  },
  components: { 
    Loader, 
    AutoUpdatesCardSkeleton, 
    ButtonSwitch 
  },
  data: function () {
    return {
      submitAutoUpgradeThemes: false,
      submitAutoUpgradePlugins: false,
      submitAutoUpgrades: false,
      autoUpdatesEnabled: false,
      fetching: true,
      fetchingAutoUpdates: true,
      submitting: false,
      autoUpdates: {},
      options: [
        {
          label: this.$t('heading.instance.advanced.autoUpdates.upgrade.option.latest'),
          value: 1,
        },
        {
          label: this.$t('heading.instance.advanced.autoUpdates.upgrade.option.minor'),
          value: 2
        },
      ],
      applicationId: this.$route.params.id,
    };
  },
  methods: {
    async toggleAutoUpgradeThemes() {
      this.submitAutoUpgradeThemes = true

      const newData = {
        auto_update: this.autoUpdates.auto_update,
        auto_update_plugins: this.autoUpdates.auto_update_plugins,
        auto_update_themes: !this.autoUpdates.auto_update_themes ? 1 : 0,
      };

      try {
        const result = await this.saveChanges(newData)
        
        if(result) {
            this.autoUpdates.auto_update_themes = !this.autoUpdates.auto_update_themes;
        }
      } catch(error) {
        console.log(error)
      } finally {
        this.submitAutoUpgradeThemes = false
      }
    },
    async toggleAutoUpgradePlugins() {
      this.submitAutoUpgradePlugins = true

      const newData = {
        auto_update: this.autoUpdates.auto_update,
        auto_update_plugins: !this.autoUpdates.auto_update_plugins ? 1 : 0,
        auto_update_themes: this.autoUpdates.auto_update_themes,
      };
      try {
        const result = await this.saveChanges(newData)
        
        if(result) {
            this.autoUpdates.auto_update_plugins = !this.autoUpdates.auto_update_plugins;
        }
      } catch(error) {
        console.log(error)
      } finally {
        this.submitAutoUpgradePlugins = false
      }
    },
    async toggleAutoUpgrades() {
      this.submitAutoUpgrades = true

      const newData = {
        auto_update: !this.autoUpdates.auto_update ? 1 : 0,
        auto_update_plugins: this.autoUpdates.auto_update_plugins,
        auto_update_themes: this.autoUpdates.auto_update_themes,
      };
      try {
        const result = await this.saveChanges(newData)

        if(result) {
          this.autoUpdates.auto_update = !this.autoUpdates.auto_update;
        }
      } catch(error) {
        console.log(error)
      } finally {
        this.submitAutoUpgrades = false
      }
    },
    async changeAutoUpdate(value) {
        this.submitAutoUpgrades = true

        const newData = {
          auto_update: value,
          auto_update_plugins: this.autoUpdates.auto_update_plugins,
          auto_update_themes: this.autoUpdates.auto_update_themes,
        };

        try {
          const result = await this.saveChanges(newData)
          
          if(result) {
              this.autoUpdates.auto_update = value;
          }
        } catch(error) {
          console.log(error)
        } finally {
          this.submitAutoUpgrades = false
        }
      },
    
    fetchAutoUpdates() {
      this.fetchingAutoUpdates = true;
      Api.get(`/instances/${this.applicationId}/wordpress/auto-update`)
        .then((response) => {
          this.autoUpdates = response.data.data;
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.fetchingAutoUpdates = false;
        });
    },
    async saveChanges(data = this.autoUpdates) {
      this.submitting = true;

      try {
        await Api.put(
          `/instances/${this.applicationId}/wordpress/auto-update`,
          data
        )
        this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.$t('notification.instance.settings.update')
          });
          return true
      } catch(error) {
        this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
          return false
      } finally {
        this.submitting = false;
      }
    },
  },
};
</script>